import React from "react";
import Head from "next/head";

const NotFound = () => {
  return (
    <>
      <Head>
        <title>404: Not Found</title>
      </Head>
      <h1 className="flex justify-center mt-64 p-4 text-3xl text-red-600">
        404: Not Found
      </h1>
    </>
  );
};

export default NotFound;
